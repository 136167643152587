.pageender{
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	margin: 0.75rem auto;

	&-dot{
		width: 0.75rem;
		height: 0.75rem;
		border: 0.125rem solid $bordergrey;
		background: $white;
		border-radius: 50%;
		margin: 0 0.375rem;
	}
}