a.blog-post {
	text-decoration: none;
	display: block;
}

.blog-post {
	border-radius: 0.75rem;
	/*box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12),
							0 1px 2px rgba(0, 0, 0, 0.24);*/
	box-shadow: 0 10px 30px -10px rgba(0, 0, 0, 0.2);
	border: 0.1rem solid $backgroundgrey;
	width: 100%;
	margin-right: 1.35rem;
	margin-bottom: 1.25rem;
	text-decoration: none;
	overflow: hidden;
	text-align: left;

	&.draft{
		border: 0.25rem dashed $bordergrey;
	}

	&-container {
		margin: 1rem;

		@media only screen and (max-width: 768px) {
			margin: 0.75rem 0rem;
		}

		.btn {
			cursor: pointer;
		}
	}

	@media only screen and (max-width: 768px) {
		max-width: 650px;
		margin: 0 auto;
		margin-bottom: 1.25rem;
	}

	&-image {
		height: 17.5rem;
		width: 100%;
		object-fit: cover;

		&.resimage{
			max-width: 100%;
			max-height: 100%;
			width: 100%;
		}

		&-container {
			height: 17.5rem;
			overflow: hidden;
			background: $backgroundgrey;
			text-align: center;
			display: flex;
			justify-content: center;
			align-items: center;
			padding: 0;
		}
	}

	&-maindetails {
		padding: 0.875rem;
		color: $subtleblack;
		/*border: 0.1rem solid #f1f1f1;*/
	}

	&-title {
		font-weight: 600;
		text-transform: capitalize;
		font-size: 1.45rem;
		height: 4.25rem;
		line-clamp: 2;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		overflow: hidden;
	}

	&-snippet {
		font-weight: 400;
		font-size: 0.975rem;
		color: $textgrey;
		margin: 0.45rem 0;
		line-clamp: 2;
		line-height: 1.5rem;
		min-height: 3.1rem;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		overflow: hidden;
	}

	&-pagination {
		text-align: center;
	}

	&-creator,
	&-createdAt {
		color: #898989;
		font-size: 0.805rem;
		font-weight: 500;
	}

	&-controls {
		padding-top: 0.75rem;
	}

	&-analytics{
		color: $black;
		font-weight: 500;
	}
}
