.fixedcontainer {
	padding: 0.75rem;
	max-width: 1100px;
	margin: 0 auto;
}

.resimage {
	max-width: 90%;
	max-height: 90%;
}

.centeralign {
	text-align: center;
}

.alignright {
	display: flex;
	justify-content: flex-end;
	text-align: right;
}

.dropzone {
	padding: 0.75rem;

	&class {
		padding: 0.65rem;
		background: #313131;
		display: flex;
		flex-flow: column;
		justify-content: center;
	}
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
input[type="number"] {
	-moz-appearance: textfield;
}

.accessibility{
	background: transparent;
	border: none;
	box-shadow: none;
	padding: 0;
}