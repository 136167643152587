&-myprofile{
	&-row{
		&-image{
			height: 15.75rem;
			border-radius: 50%;
			object-fit: cover;
			border: 0.1rem solid $bordergrey;
			
			&-container{
				display: flex;
				height: 15.75rem;
				text-align: center;
				justify-content: center;
				align-items: center;

				.MuiSvgIcon-root {
					font-size: 15.75rem;
				}
			}
		}
	}

	.form-control{
		height: auto;
	}
}