&-viewposts {
	.blogposts {
		text-align: center;
		height: auto;
		display: block;
		overflow: auto;
		width: 100%;

		&-list {
			height: auto;
			width: 100%;
			display: block;
			overflow: auto;
			padding: 0 0.25rem;
			display: flex;
			text-align: center;
			justify-content: center;
		}
	}

	.inputarea {
		padding: 0.625rem !important;
	}

	.daterangepicker {
		text-align: center;
	}
}
