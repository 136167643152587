*{
	box-sizing: border-box;
}

body {
	line-height: 1.61;
	background: $white;
	color: $black;
	padding: 0;
	margin: 0;
	width: 100%;
	max-width: 100%;
	overflow-x: hidden;
	font-family: "Roboto", sans-serif;
}