.nonefound{
	padding: 1.5rem 0;
	display: flex;
	flex-flow: column;
	align-items: center;
	justify-content: center;
	min-height: 40vh;

	.MuiSvgIcon-root{
		font-size: 5rem;
	}

	.resimage{
		max-height: 30rem;
	}

	.extralargelabel{
		font-size: 1.5rem;
	}
}