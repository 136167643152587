.dashboard{
	@import "./myprofile";
	@import "./createpost";
	@import "./viewposts";
	@import "./categories";

	&-tabs{
		width: 100%;
		border-bottom: 0.1rem solid $backgroundgrey;

		.nopadding{
			padding: 0 0.5rem;
			// display: flex;
			overflow-x: auto;
			// align-items: center;
			// justify-content: flex-start;
			white-space: nowrap;
			text-align: left;
			-ms-overflow-style: none;  /* IE and Edge */
			scrollbar-width: none;  /* Firefox */

			&::-webkit-scrollbar {
			  display: none;
			}
		}

		.tabswitcher{
			display: inline-block;
			padding: 0.75rem;
			transition: 0.75s;
			text-decoration: none;
			color: $black;
			font-weight: 500;
			font-size: 0.925rem;
			border-bottom: 0.15rem solid $white;

			.MuiSvgIcon-root{
				font-size: 1.25rem;
			}

			&.active{
				color: $primary;
				border-bottom: 0.15rem solid $primary;
			}
		}
	}
}