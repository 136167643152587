// Loader Component
.loader {
	margin: 0 auto;
	max-width: 80%;
	background: $black;
	color: $white;
	padding: 1.5rem;
	display: flex;
	flex-flow: column;
	justify-content: center;
	align-items: center;
	border-radius: 0.25rem;

	.MuiSvgIcon-root {
		font-size: 4rem;
		-webkit-animation: load 1.4s infinite linear;
		animation: load 1.4s infinite linear;
	}

	@-webkit-keyframes load {
		0% {
			-webkit-transform: rotate(0deg);
			transform: rotate(0deg);
		}
		100% {
			-webkit-transform: rotate(360deg);
			transform: rotate(360deg);
		}
	}
	@keyframes load {
		0% {
			-webkit-transform: rotate(0deg);
			transform: rotate(0deg);
		}
		100% {
			-webkit-transform: rotate(360deg);
			transform: rotate(360deg);
		}
	}

	&-container {
		position: fixed;
		background: rgba(0, 0, 0, 0.7);
		overflow-y: auto;
		top: 0;
		bottom: 0;
		right: 0;
		left: 0;
		display: flex;
		align-items: center;
		z-index: 1050;
	}

	&-text {
		font-size: 1.1rem;
		margin-top: 0.75rem;
	}
}
