&-createpost{
	.form-control{
		height: auto;
	}

	textarea.form-control{
		min-height: 25vh;
	}

	&-previewtoggle{
		text-align: center;
		margin-bottom: 1rem;
	}
}