// Blog Post Preview Page

.blogpost {
	margin: 0 0 2rem 0;
}

.blogpost-userdetails {
	display: flex;
	flex-flow: column;
	justify-content: center;
	text-align: center;
	padding: 0.75rem 0 !important;
	padding-top: 0 !important;
}

.blogpost-userdetails-usermain {
	display: flex;
	align-items: flex-start;
	flex-flow: column;
	text-align: left;
	justify-content: center;
	padding: 0.75rem 0.875rem;
}

.blogpost-userdetails .blogpost-userdetails-metadata {
	text-align: center;
	justify-content: center;
	width: 100%;
}

.blogpost-userdetails-usermain .username {
	font-weight: 500;
	font-size: 1.1rem;
	text-transform: capitalize;
}

.blogpost-userdetails-usermain .username .readingtime {
	font-weight: 500;
	font-size: 0.875rem;
	color: #707070;
}

.blogpost-userdetails-usermain .createdat {
	font-weight: 500;
	font-size: 0.925rem;
	color: $textgrey;
}

.blogpost-mainimage {
	background: $white;
	display: flex;
	align-items: center;
	/*min-height: 15rem;
					max-height: 25rem;*/
	justify-content: center;
	width: 100%;
	max-height: 67.5vh;
	// overflow: hidden;
}

.blogpost-mainimage .resimage {
	border-radius: 0.75rem;
	min-width: 60%;
	max-width: 85%;
	max-height: 63.5vh;
	box-shadow: 0 0px 50px rgba(74, 74, 139, 0.25);
}

.more-share {
	text-align: center;
	color: #313131;
	font-weight: 500;
}

.sharelabel {
	margin-bottom: 0.75rem;
	color: #4a5568;
	font-weight: 700;
}

.sharelabel.savinglabel {
	font-size: 1.125rem;
}

.blogpost-userdetails-userimage {
	height: 4.65rem;
	width: 4.65rem;
	min-width: 4.65rem;
	border-radius: 50%;
	max-width: 10%;
}

.blogpost-userdetails-userimage .userimage {
	height: 4.35rem;
	width: 4.35rem;
	max-height: 100%;
	max-width: 100%;
	border-radius: 50%;
	border: 0.125rem solid #1976d2;
	object-fit: cover;
	padding: 0.15rem;
}

.blogpost-userdetails-sociallinks {
	display: flex;
	justify-content: center;
	padding: 0.75rem 0;
}

.sociallink {
	color: #313131;
	font-size: 1.25rem;
	text-decoration: none;
	padding: 0 0.875rem;
	margin: 0 0.5rem;
}

.blogpost-categories {
	margin-bottom: 1rem;
	color: #707070;
	text-align: center;
	padding: 0;
}

.blogpost-categories a {
	margin-right: 0.35rem;
	margin-top: 0.35rem;
	text-decoration: none;
}

.blogpost-categories a:hover {
	color: #707070;
}

.category-snip {
	border-radius: 3.5rem;
	background: #efefef;
	display: inline-block;
	color: #707070;
	text-transform: uppercase;
	font-size: 0.875rem;
	font-weight: 500;
	padding: 0.25rem 1rem;
	text-decoration: none;
	margin-left: 0.5rem;
}

.blogpost-maincontent {
	padding: 1.25rem 0.75rem;
	max-width: 650px;
	margin: 0 auto;
}

.sharebutton {
	margin: 0 0.5rem;
	font-size: 1.125rem !important;
	font-weight: 600 !important;
	color: #4a5568 !important;
	outline: none;
}

.content {
	color: #4a5568;
	line-height: 2rem;
}

.content h1,
.content h2,
.content h3,
.content h4,
.content h5,
.content h6 {
	color: #000000;
	font-weight: 400;
}

.content img {
	margin-top: 0.5rem;
	margin-bottom: 0.5rem;
	border-radius: 0.75rem;
	box-shadow: 0 0 15px rgba(74, 74, 139, 0.25);
}

.more-buttons {
	padding: 0.75rem;
	margin: 1rem 0;
	text-align: center;
}

a.more-buttons-primary {
	background: #7aa9f9;
	border-radius: 3.5rem;
	text-decoration: none;
	padding: 1rem 2.5rem;
	display: inline-block;
	color: #ffffff;
	font-weight: 500;
}

a.more-buttons-secondary {
	background: #bfdbd6;
	border-radius: 3.5rem;
	text-decoration: none;
	padding: 1rem 2.5rem;
	display: inline-block;
	color: #212121;
	margin-left: 1rem;
	font-weight: 500;
}

@media only screen and (max-width: 768px) {
	.more-buttons-options {
		display: flex;
		flex-flow: column;
	}

	.more-buttons-options a {
		width: 100%;
		display: block;
	}

	a.more-buttons-secondary {
		margin-left: 0;
		margin-top: 0.875rem;
	}
}
