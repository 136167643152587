.fullpagemodal{
	position: fixed;
	overflow: auto;
	top: 0;
	right: 0;
	left: 0;
	z-index: 997;
	height: 100%;
	bottom: 0;
	background: $white;

	@media print{
		position: relative;
		overflow-y: auto !important;
		min-width: 100vw;
		margin: 0;
	}

	&-top{
		background: $primary;
		color: $white;

		.row{
			align-items: center;
		}

		&-name{
			text-transform: uppercase;
			font-size: 1.175rem;
			font-weight: 600;
			letter-spacing: 0.075rem;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}

		&-option{
			display: flex;
			justify-content: flex-end;

			.closeicon{
				color: $white;
			}
		}
	}
}