.appheader{
	padding: 0.5rem 0;
	border-bottom: 0.1rem solid $backgroundgrey;
	border-top: 0.35rem solid $primary;
	
	a{
		text-decoration: none;
	}

	.headerleft{
		display: flex;
		align-items: center;
		font-weight: 600;
		font-size: 1.065rem;

		.companylogo{
			max-width: 2.5rem;
			max-height: 2.5rem;
		}

		a{
			text-decoration: none;
			color: $black;
			display: flex;
			align-items: center;
		}
	}

	.headerright{
		display: flex;
		justify-content: flex-end;
		align-items: center;
	}
}