&-managecategories{
	&-categorylist{
		&-category{
			margin: 0.5rem 0;
		}
		
		&-categoryheading{
			font-weight: 500;
			text-transform: uppercase;
			font-size: 1.125rem;
		}
	}
}